<template>
  <div class="mt-2">
    <!-- <div :style="[!noInnerWidth ? { width: width } : {}]"> -->
    <div :style="{ width: !noInnerWidth ? width : 'auto' }">
      <div
        :style="{
          'overflow-y':
            items.length > (communication_types.length || 5)
              ? 'auto'
              : 'visible',
        }"
      >
        <b-table
          :busy="isLoading"
          head-variant="light"
          :no-local-sorting="true"
          :items="items"
          :fields="getAllFields"
          :current-page="currentPage"
          per-page="0"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :sticky-header="height"
          no-border-collapse
          responsive
          show-empty
          empty-text="Non ci sono informazioni da mostrare"
          small
          @sort-changed="sortingChanged"
        >
          <template #table-busy>
            <div class="text-center my-2">
              <base-icon name="loading" width="35" height="35" />
              <p>Operazione in corso...</p>
            </div>
          </template>
          <!-- <template #cell()="data">
            {{ data.unformatted }}
          </template -->
          <template
            v-slot:head(check)="data"
            v-if="hasChecks && !isCheckExclusive && items.length"
          >
            <base-checkbox
              v-model="checkAll"
              name="all"
              class="check"
              groupLabel=""
              :label="data.label"
              @change="
                $emit('interaction');
                onSelectAllRows();
              "
            >
            </base-checkbox>
          </template>
          <template #cell(customer)="{ item }" v-if="items.length">
            <span
              v-if="
                !Array.isArray(item.attributables) && !item.attributables.length
              "
              >{{
                item.status_registry.value === 1
                  ? item.attributables.CNAM
                  : item.attributables.SURN + " " + item.attributables.NAME
              }}</span
            >
          </template>
          <template #cell(code)="{ item }" v-if="items.length">
            {{
              item.registry_groups
                ? item.registry_groups.map((e) => e.code).join(", ")
                : ""
            }}
          </template>
          <template #cell(status_registry)="{ item }" v-if="items.length">
            {{ item.status_registry.value === 1 ? "G" : "F" }}
          </template>
          <template #cell(insurance_policy)="{ item }" v-if="items.length">
            <span :class="{ 'text-red': !item.insurance_policies.length }">
              {{
                item.insurance_policies.filter((insurance_policy) =>
                  [0, 1, 2].includes(insurance_policy.status_policy.value)
                ).length
                  ? item.insurance_policies.filter((insurance_policy) =>
                      [0, 1, 2].includes(insurance_policy.status_policy.value)
                    ).length
                  : "no pol"
              }}
            </span>
          </template>
          <!-- <template #cell(note)="{ item }" v-if="items.length">
              {{
                item.tasks.length
                  ? item.tasks.filter((task) => task.task_type.value === 2)
                      .length
                  : 0
              }}
            </template> -->
          <template #cell(note_counter)="{ item }" v-if="items.length">
            <div
              :role="
                item.tasks.filter((task) => task.task_type.value === 2).length
                  ? 'button'
                  : null
              "
              class="info-box float-left"
              @click="
                item.tasks.filter((task) => task.task_type.value === 2).length
                  ? openNoteDetailModal(
                      item.tasks.filter((task) => task.task_type.value === 2)
                    )
                  : null
              "
              v-b-tooltip="
                toTooltipTask(
                  item.tasks.filter((task) => task.task_type.value === 2),
                  'Note'
                )
              "
            >
              <!-- :title="
                  item.tasks.filter((task) => task.task_type.value === 2).length
                    ? 'Mostra Note'
                    : null
                " -->
              {{
                item.tasks.filter((task) => task.task_type.value === 2).length
              }}
            </div>
          </template>
          <template #cell(broker)="{ item }" v-if="items.length">
            {{
              item.insurance_policies
                ? item.insurance_policies
                    .filter((e) => {
                      return e.broker && e.broker.status_broker.value === 0;
                    })
                    // filtrare solo quelle che si trovano in stati particolari:
                    // 0: non perfezionata,
                    // 1: perfezionata
                    // 2:sospesa
                    .filter((e) => {
                      return (
                        e.status_policy &&
                        [0, 1, 2].includes(e.status_policy.value)
                      );
                    })
                    .map((e) => e.broker.attributables.BUID)
                    .filter((value, index, e) => e.indexOf(value) === index)
                    .join(", ")
                : ""
            }}
          </template>
          <!-- <template #cell(document_counter)="{ item }" v-if="items.length">
              {{
                item.documents.length
                  ? item.documents.length
                  : 0,
              }}
            </template> -->
          <template #cell(document_counter)="{ item }" v-if="items.length">
            <div
              :role="item.documents.length ? 'button' : null"
              class="info-box float-left"
              @click="
                item.documents.length
                  ? openDocumentDetailModal(item.documents)
                  : null
              "
              v-b-tooltip="
                toTooltipTask(item.documents, 'Documenti', [
                  { label: 'Titoli', key: 'title' },
                  { label: 'Descrizione', key: 'note' },
                  { label: 'Ultimo aggiornamento', key: 'last_update' },
                ])
              "
            >
              <!-- :title="item.documents.length ? 'Mostra Documenti' : null" -->
              {{ item.documents.length }}
            </div>
          </template>
          <template #cell(primary_group)="{ item }" v-if="items.length">
            {{
                  item.registry_groups
                  ? item.registry_groups.length && item.registry_groups.find(
                    registry_group => registry_group.pivot.primary.value === 'Y'
                  ) ? item.registry_groups.find(
                    registry_group => registry_group.pivot.primary.value === 'Y'
                  ).code: ""
                  : "",
            }}
          </template>
          <template #cell(is_coordinator)="{ item }" v-if="items.length">
            <base-checkbox
              name="is_coordinator"
              v-model="
                item.registry_groups[
                  getRegistryGroupIndex(item.registry_groups)
                ].pivot.is_coordinator.value
              "
              chk_val="Y"
              unchk_val="N"
              @input="
                setIsCoordinator(
                  item.registry_groups[
                    getRegistryGroupIndex(item.registry_groups)
                  ].pivot
                )
              "
            />
            <!-- REVIEW coul be true|false, Y|N depending on backend inplementation -->
          </template>
          <template #cell(communication_type)="{ item }" v-if="items.length">
            <base-select
              label=""
              name="communication_type"
              v-model="
                item.registry_groups[
                  getRegistryGroupIndex(item.registry_groups)
                ].pivot.communication_type.value
              "
              :options="communication_types"
              @select="setCommunicationType"
              @remove="$emit('interaction')"
            />
          </template>
          <template #cell(address)="{ item }">
            <span v-html="toInfoData(item, 'address', 0)"></span>
          </template>
          <template #cell(domicilio)="{ item }">
            <span
              v-html="
                toRelationString(
                  'correspondences',
                  item.correspondences,
                  ['ADDR'],
                  [1]
                )
              "
            ></span>
          </template>
          <template #cell(phon)="{ item }">
            <span
              v-html="
                toRelationString(
                  'address_books',
                  item.address_books,
                  ['PHON'],
                  [0]
                )
              "
            ></span>
          </template>
          <template #cell(mobile)="{ item }">
            <span
              v-html="
                toRelationString(
                  'address_books',
                  item.address_books,
                  ['CELL'],
                  [0]
                )
              "
            ></span>
          </template>
          <template #cell(email1)="{ item }">
            <span
              v-html="
                toRelationString(
                  'address_books',
                  item.address_books,
                  ['MAIL'],
                  [0]
                )
              "
            ></span>
          </template>
          <template #cell(email2)="{ item }">
            <span
              v-html="
                toRelationString(
                  'address_books',
                  item.address_books,
                  ['MAIL'],
                  [1]
                )
              "
            ></span>
          </template>
          <template #cell(pec)="{ item }">
            <span
              v-html="
                toRelationString(
                  'address_books',
                  item.address_books,
                  ['PEC'],
                  [0]
                )
              "
            ></span>
          </template>
          <template #cell(relative_types)="{ item }" v-if="items.length">
            <base-select
              label=""
              name="relation_types"
              v-model="item['relation_type_id']"
              :options="getFilteredRelativeTypes(item)"
            />
          </template>
          <template #cell(relation)="{ item }" v-if="items.length">
            {{
              item.status_registry.value === 1
                ? item.pivot.relative_type.male
                : item.attributables.SEX === "M"
                ? item.pivot.relative_type.male
                : item.pivot.relative_type.female
            }}
          </template>
          <template #cell(actions)="row">
            <div class="action-buttons">
              <b-button
                v-if="actions.includes('infomodal')"
                size="sm"
                variant="lisaweb"
                @click="openModal(row.item, row.index, $event.target)"
                class="mt-1 mr-1"
                title="Modale Dettagli"
              >
                <b-icon icon="eye"></b-icon>
              </b-button>
              <b-button
                v-if="actions.includes('details')"
                size="sm"
                variant="lisaweb"
                @click="row.toggleDetails"
                class="mt-1 mr-1"
                :title="
                  row.detailsShowing ? 'Chiudi dettagli' : 'Apri dettagli'
                "
              >
                <b-icon v-if="row.detailsShowing" icon="chevron-up"></b-icon>
                <b-icon v-else icon="chevron-down"></b-icon>
              </b-button>
              <b-button
                v-if="actions.includes('edit')"
                size="sm"
                variant="lisaweb"
                @click="onEdit(row.item.id, row.item)"
                class="mt-1 mr-1"
                title="Modifica"
              >
                <b-icon icon="pencil-square"></b-icon>
              </b-button>

              <b-button
                v-if="actions.includes('destroy')"
                size="sm"
                variant="lisaweb"
                @click="onDestroy(row.item.id)"
                class="mt-1 mr-1"
                title="Elimina"
              >
                <b-icon icon="trash"></b-icon>
              </b-button>
            </div>
          </template>
          <template #cell(rowSelector)="row">
            <div class="action-buttons">
              <b-button
                size="sm"
                variant="lisaweb"
                @click="onChosen(row.item)"
                class="mt-1 mr-1"
                title="Seleziona"
              >
                Seleziona
              </b-button>
            </div>
          </template>
          <template #row-details="row">
            <b-card>
              <b-row
                class="mb-2"
                v-for="(value, key) in customized(row.item)"
                :key="key"
              >
                <b-col sm="3" class="text-sm-right"
                  ><b>{{ key | capitalize }}:</b></b-col
                >
                <b-col>{{ value }}</b-col>
              </b-row>
            </b-card>
          </template>
        </b-table>
      </div>
      <b-row v-if="!noPagination">
        <div class="show-text">
          <p>Mostra</p>
        </div>
        <div class="group">
          <b-form-group
            label-for="per-page-select"
            label-cols-sm="3"
            label-cols-md=""
            label-cols-lg="3"
            label-size="sm"
            md="auto"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              style="width: 68px"
              size="sm"
              @change="onPageChange(1)"
              :disabled="!items.length"
            ></b-form-select>
          </b-form-group>
        </div>

        <div class="page">
          <p>di {{ totalItems }} per pagina</p>
        </div>
        <div class="pagination" v-if="totalItems">
          <b-pagination
            v-model="currentPage"
            limit="3"
            :total-rows="totalItems"
            :per-page="perPage"
            size="sm"
            @change="onPageChange"
          ></b-pagination>
        </div>
        <div class="input_pagination" v-if="totalItems && !noInputPaginator">
          <base-input-pagination
            @pagination="onInputPagination"
          ></base-input-pagination>
        </div>
      </b-row>
      <b-modal
        :id="infomodalName"
        @hidden="onModalHidden"
        :title="modalTitle"
        size="xl"
        ok-only
        ok-variant="lisaweb"
        header-bg-variant="lisaweb"
        no-close-on-backdrop
      >
        <div>
          <b-tabs content-class="pt-1" lazy v-model="tabIndex">
            <!-- :active="index === tabIndex" -->
            <b-tab
              v-for="(tab, index) in tabs"
              :active="index === tabIndex"
              :key="index"
              :title="tab.text"
              @click="setTab(tab, index)"
              title-item-class="lisaweb"
            >
              <button-group-tab> </button-group-tab>
              <keep-alive>
                <component
                  v-if="selected === tab.name"
                  :is="selected"
                  :resourceId="modalResourceId"
                  @fetch="fetch"
                ></component>
              </keep-alive>
            </b-tab>
          </b-tabs>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import template from "./template";
import BaseIcon from "@/components/BaseIcon";
import ButtonGroupTab from "@/components/ButtonGroupTab";
import General from "@/components/wallet/registries/details/General";
import Correspondences from "@/components/wallet/registries/details/Correspondences";
import InsurancePolicy from "@/components/wallet/registries/details/InsurancePolicy";
import InsuranceAncillary from "@/components/wallet/registries/details/InsuranceAncillary";
import Takings from "@/components/wallet/registries/details/Takings";
import Claim from "@/components/wallet/registries/details/Claim";
import Appointments from "@/components/wallet/registries/details/Appointments";
import Todo from "@/components/wallet/registries/details/Todo";
import Notes from "@/components/wallet/registries/details/Notes";
import Group from "@/components/wallet/registries/details/Group";
import Documents from "@/components/wallet/registries/details/Documents";
import Relations from "@/components/wallet/registries/details/Relations";
import Prints from "@/components/wallet/registries/details/Prints";
import BaseCheckbox from "@/components/form/BaseCheckbox";
import BaseRadio from "@/components/form/BaseRadio";
import BaseSelect from "@/components/form/BaseSelect";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import { mapGetters } from "vuex";
import BaseInputPagination from "@/components/form/BaseInputPagination";

export default {
  name: "RegistrtRelationsTable",
  extends: template,
  data() {
    return {
      args: null,
      relativeTypes: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      tabs: [
        { name: "General", text: "Generale" },
        { name: "Correspondences", text: "Indirizzi" },
        { name: "InsurancePolicy", text: "Polizze" },
        { name: "InsuranceAncillary", text: "Titoli" },
        { name: "Takings", text: "Incassi" },
        { name: "Claim", text: "Sinistri" },
        { name: "Appointments", text: "Appuntamenti" },
        { name: "Notes", text: "Note" },
        { name: "Todo", text: "Attività" },
        { name: "Group", text: "Gruppi" },
        { name: "Documents", text: "Doc" },
        { name: "Relations", text: "Relazioni" },
        { name: "Prints", text: "Stampe" },
      ],
      selected: "General", // dynamically load selected component in tabs
      modalResourceId: null, // prop for the dynamic components
      tabIndex: 0, // remember last visited tab: set active tab when modal gets opened
      communication_types: [],
      communication_type: 5, // capogruppo
    };
  },
  inject: ["args"],
  components: {
    BaseIcon,
    BaseInputPagination,
    General,
    Correspondences,
    InsurancePolicy,
    InsuranceAncillary,
    Takings,
    Claim,
    Appointments,
    // Claim: () => import("@/components/wallet/registries/details/Claim"),
    // Appointments: () =>
    //   import("@/components/wallet/registries/details/Appointments"),
    Notes,
    Group,
    Documents,
    Relations,
    Prints,
    ButtonGroupTab,
    BaseCheckbox,
    BaseRadio,
    BaseSelect,
    Todo,
  },
  beforeMount() {
    this.relativeTypes = this.getRelativeTypes();
    let gId = this.getGroupId();
    if (gId) {
      // this.isLoading = true;
      const Repo = RepositoryFactory.get("registry_group");
      // FIX: 1
      // Repo.createpivot(gId, "registry").then((response) => {
      // Repo.createpivot().then((response) => {
      Repo.pivot_form(gId, "registry").then((response) => {
        console.log("*************** communication_type ****************");
        console.log(response.data.fields.communication_type.options);
        this.communication_types =
          response.data.fields.communication_type.options;
        // this.isLoading = false;
      });
    }

    // this.args = this.$parent.args;
  },
  computed: {},
  methods: {
    openNoteDetailModal(array) {
      this.$emit("open_note", array);
    },
    openDocumentDetailModal(array) {
      this.$emit("open_document", array);
    },
    getGroupId() {
      let group_id;
      const filter = this.$parent.$data.filter;
      if (filter && Object.keys(filter).length && filter.byRegistryGroup) {
        group_id = filter.byRegistryGroup.id;
      }
      // se group_id non è null, vedo se c'è un ! davanti, altrimenti ...
      return group_id
        ? !group_id.toString().startsWith("!")
          ? group_id
          : group_id.toString().slice(1)
        : group_id;
    },
    getRegistryGroupIndex(registry_groups) {
      let group_id = this.getGroupId();
      let ret = -1;
      ret = registry_groups.findIndex((elem) => elem.id == group_id);
      // console.debug("getRegistryGroupIndex ret: ", ret);
      return ret;
    },
    setIsCoordinator(item) {
      if (item.is_coordinator.value === "Y") {
        // REVIEW coul be true|false, Y|N depending on backend inplementation
        // caller has to clear the check from all but this registry_id
        this.$emit("coordinator", item.registry_group_id, item.registry_id);
      } else {
        this.$emit("interaction");
      }
    },
    setCommunicationType(val) {
      // fa solo emit per notificare che è stato scelto qualcosa...
      this.$emit("communication", val);
    },
    customized(item) {
      let c = {};
      c.produttore = item.attributables.PROF;
      if (item.status_registry.value == 0) {
        c.data_di_nascita = item.attributables.DOB;
        c.luogo_di_nascita = this.$store.state.auth.councils.find(
          (e) => e.id == item.attributables.POB
        ).title;
        c.numero_documento = item.attributables.DOCN;
      }
      c.indirizzo = this.toRelationString(
        "correspondences",
        item.correspondences,
        ["ADDR"],
        [0]
      ); // Residenza / Sede Legale = 0
      c.domicilio = this.toRelationString(
        "correspondences",
        item.correspondences,
        ["ADDR"],
        [1]
      ); // Domicilio = 1
      c.indirizzo_alternativo = this.toRelationString(
        "correspondences",
        item.correspondences,
        ["ADDR"],
        [2]
      ); // Indirizzo Alternativo = 2
      let customAttributes = this.convertFromCustomAttributesToFields(
        this.customAttributes()(this.repository)
      );
      Object.keys(customAttributes).forEach((i) => {
        let value = customAttributes[i].key.split(".");
        let label = customAttributes[i].label;
        c[label] = item.attributables[value[value.length - 1]];
      });

      return c;
    },
    getFilteredRelativeTypes(item) {
      return this.relativeTypes.map((relative_type) => {
        return {
          value: relative_type.value,
          text:
            item.status_registry.value === 1
              ? relative_type.male
              : item.attributables.SEX === "M"
              ? relative_type.male
              : relative_type.female,
        };
      });
    },
    onModalHidden() {
      this.setTab({ name: "General", text: "Generale" }, 0);
    },
    ...mapGetters("auth", {
      getRelativeTypes: "relative_types",
    }),
  },
};
</script>
<style lang="scss">
.text-red {
  color: red;
}
:deep(.check) {
  width: 1px;
  // padding-left: 10px;
}
:deep(.check .form-group) {
  margin-bottom: unset !important;
}
</style>
