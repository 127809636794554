<template>
  <div class="mx-1">
    <validation-observer ref="observer" v-slot="{ handleSubmit, invalid }">
      <b-form @submit.prevent="handleSubmit(createReport)">
        <b-card>
          <b-card-text>
            <base-datepicker
              vid="data_registrazione"
              name="data_registrazione"
              label="Data di registrazione"
              v-model="form.request_input.data_registrazione"
            />
            <base-select
              name="forma_pagamento"
              vid="forma_pagamento"
              label="Forma di pagamento"
              v-model="form.request_input.forma_pagamento"
              :options="opt_treasuries"
              :rules="{ required: true }"
            />
          </b-card-text>
          <b-button
            type="submit"
            :disabled="invalid"
            variant="outline-lisaweb"
            size="sm"
            class="float-right"
          >
            Salva
          </b-button>
        </b-card>
      </b-form>
    </validation-observer>
  </div>
</template>
<script>
import BaseSelect from "@/components/form/BaseSelect";
import BaseDatepicker from "@/components/form/BaseDatepicker";
import { mapGetters } from "vuex";
import common from "@/components/reports/common.vue";
export default {
  extends: common,
  data() {
    return {
      opt_treasuries: [],
      form: {
        format: 'pdf',
        request_input: {
          data_registrazione: null,
          forma_pagamento: [],
        },
      },
    };
  },
  components: {
    BaseSelect,
    BaseDatepicker,
  },
  methods: {
    ...mapGetters("auth", {
      getAllTreasuries: "allTreasuries",
    }),
  },
  mounted() {
    this.opt_treasuries = this.getAllTreasuries();
  },
};
</script>
