<template>
  <div class="mx-1">
    <validation-observer ref="observer" v-slot="{ handleSubmit, invalid }">
      <b-form @submit.prevent="handleSubmit(createReport)">
        <b-card>
          <b-card-text>
            <base-radio
                :name="beFormReport.format.label"
                vid="format"
                :label="beFormReport.format.label"
                v-model="form.format"
                :options="allowedFormatOptions"
                :rules="{ required: true }"
            />
            <div>
              <b-button-toolbar>
                <b-button-group>
                  <base-input
                      name="Anagrafica"
                      label="Anagrafica"
                      v-model="registry_data"
                      :readonly="true"
                      :rules="{ required: true }"
                  />
                  <b-button
                      class="mt-4 btn-quick"
                      size="sm"
                      text="Button"
                      variant="lisaweb"
                      title="Ricerca Veloce"
                      @click="openQuickSearchRegistry"
                  >
                    <b-icon icon="search"></b-icon
                    >
                  </b-button>
                  <b-button
                      v-if="form.id_registro"
                      class="mt-4 btn-quick ml-1"
                      size="sm"
                      text="Button"
                      variant="lisaweb"
                      @click="resetRegistryId"
                      title="Elimina Anagrafica"
                  >
                    <b-icon icon="trash"></b-icon
                    >
                  </b-button>
                </b-button-group>
              </b-button-toolbar>
            </div>
            <base-radio
                name="includi_gruppo"
                vid="includi_gruppo"
                label="Seleziona"
                v-model="form.request_input.includi_gruppo"
                :options="[
                { value: 'Y', text: 'Attiva selezione per gruppo anagrafico' },
                {
                  value: 'N',
                  text: 'Attiva selezione in base alle relazioni anagrafiche',
                },
              ]"
                @select="svuotaValori()"
                :rules="{ required: true }"
            />
            <base-select
                v-if="showGroups && form.request_input.includi_gruppo == 'Y'"
                vid="gruppo"
                name="gruppo"
                :taggable="true"
                label="Gruppo"
                v-model="form.request_input.gruppo"
                :options="formatGroups()"
                @select="mostraMembriGruppo()"
            />
            <div v-if="showMembers && form.request_input.includi_gruppo == 'Y'">
              <base-select
                  vid="membri"
                  name="membri"
                  label="Seleziona membri gruppo"
                  v-model="form.request_input.membri"
                  :options="formatMembers()"
                  :rules="{ required: true }"
                  :multiple="true"
                  :closeOnSelect="false"
              />
              <b-button
                  :pressed.sync="myToggleM"
                  variant="light"
                  class="mb-2"
                  @click="myToggleM ? selectAllMembers() : deselectAllMembers()"
              >
                {{
                  myToggleM ? "Deseleziona tutti" : "Seleziona tutti"
                }}
              </b-button
              >
            </div>
            <div
                v-if="showRelations && form.request_input.includi_gruppo == 'N'"
            >
              <base-select
                  vid="relazioni"
                  name="relazioni"
                  label="Relazioni"
                  v-model="form.request_input.relazioni_registri"
                  :options="relationOptions"
                  :multiple="true"
                  :closeOnSelect="false"
              />
              <b-button
                  :pressed.sync="myToggleR"
                  variant="light"
                  class="mb-2"
                  @click="
                  myToggleR ? selectAllRelations() : deselectAllRelations()
                "
              >{{
                  myToggleR ? "Deseleziona tutti" : "Seleziona tutti"
                }}
              </b-button
              >
            </div>
            <base-datepicker
                vid="data_da"
                name="data_da"
                label="Periodo da"
                v-model="form.request_input.data_da"
                :rules="{ required: true }"
            />
            <base-datepicker
                vid="data_a"
                name="data_a"
                label="Periodo a"
                v-model="form.request_input.data_a"
                :rules="{ required: true }"
            />
          </b-card-text>
          <b-button
              type="submit"
              :disabled="invalid"
              variant="outline-lisaweb"
              size="sm"
              class="float-right"
          >
            Salva
          </b-button>
        </b-card>
        <quick-search-registry-modal
            ref="quickSearchRegistry"
            @input="handleRegistryModalInput"
        >
        </quick-search-registry-modal>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import BaseRadio from "@/components/form/BaseRadio";
import BaseSelect from "@/components/form/BaseSelect";
import BaseInput from "@/components/form/BaseInput";
import BaseDatepicker from "@/components/form/BaseDatepicker";
import common from "@/components/reports/common.vue";
import StorageGetterMixin from "@/mixins/StorageGetterMixin";
import moment from "moment";
import {toInfoData} from "@/utils/transforms";
import QuickSearchRegistryModal from "@/components/modals/quickSearchRegistry";
import {RepositoryFactory} from "@/repositories/RepositoryFactory";

moment.locale("it");

export default {
  extends: common,
  data() {
    return {
      filter: {
        byRegistry: {
          id: null,
        },
      },
      form: {
        report_id: null,
        format: null,
        request_input: {
          id_registro: null,
          data_a: null,
          data_da: null,
          includi_gruppo: null,
          gruppo: null,
          relazioni_registri: [],
          membri: [],
        },
      },
      registry_data: null,
      groups: [],
      members: [],
      relations: [],
      relationOptions: [],
      showGroups: false,
      showMembers: false,
      showRelations: false,
      myToggleM: false,
      myToggleR: false,
    };
  },
  mixins: [StorageGetterMixin],
  components: {
    BaseRadio,
    BaseSelect,
    BaseInput,
    BaseDatepicker,
    QuickSearchRegistryModal,
  },
  methods: {
    toInfoData,
    svuotaValori() {
      if (this.form.request_input.includi_gruppo == "Y") {
        this.form.request_input.relazioni_registri = [];
      } else {
        this.form.request_input.gruppo = [];
        this.form.request_input.membri = [];
      }
    },
    selectAllMembers() {
      this.form.request_input.membri = this.members.map((member) => member.id);
    },
    deselectAllMembers() {
      this.form.request_input.membri = [];
    },
    selectAllRelations() {
      this.form.request_input.relazioni_registri = this.relations.map(
          (relation) => relation.id
      );
    },
    deselectAllRelations() {
      this.form.request_input.relazioni_registri = [];
    },
    formatGroups() {
      return this.groups.data.map((value) => {
        return {
          value: value.id,
          text: value.code,
        };
      });
    },
    formatMembers() {
      return this.members.map((registry) => {
        return {
          value: registry.id,
          text: registry.formatted_title,
        };
      });
    },
    formatRelations() {
      return this.relations.map((relation) => {
        return {
          value: relation.id,
          text: relation.formatted_title,
        };
      });
    },
    mostraMembriGruppo() {
      this.groups.data.map((group) => {
        if (group.id == this.form.request_input.gruppo) {
          this.members = group.registries;
          this.showMembers = true;
        }
      });
    },
    createReport() {
      this.$emit("saveReport", this.form);
    },
    openQuickSearchRegistry() {
      this.form.request_input.id_registro = null;
      this.$bvModal.show("quickSearchRegistryModal");
    },
    onInputRegistry(value) {
      const registry = value;
      if (registry) {
        // Registry Groups
        if (registry.registry_groups.length) {
          this.registryGroupOptions = registry.registry_groups.map(
              (registry_group) => {
                return {
                  value: registry_group.id,
                  text: `${registry_group.code}-${registry_group.title}`,
                };
              }
          );
        } else {
          this.form.request_input.gruppo = [];
          this.registryGroupOptions = [];
        }
        // Relative Types
        if (registry.registries.length) {
          this.relationOptions = registry.registries.map((registry) => {
            return {
              value: registry.id,
              text:
                  registry.status_registry.value === 1
                      ? `${registry.attributables.CREG}-${registry.pivot.relative_type.male}`
                      : registry.attributables.SEX === "M"
                          ? `${registry.attributables.SURN} ${registry.attributables.NAME} - ${registry.pivot.relative_type.male}`
                          : `${registry.attributables.SURN} ${registry.attributables.NAME} - ${registry.pivot.relative_type.female}`,
            };
          });
        } else {
          this.form.request_input.relazioni_registri = [];
          this.relationOptions = [];
        }
      }
    },
    handleRegistryModalInput(value) {
      this.onInputRegistry(value);
      this.form.request_input.id_registro = value.id;
      this.registry_data = this.toInfoData(value, "registryfullname");
      this.$bvModal.hide("quickSearchRegistryModal");
      const Repo = RepositoryFactory.get("registry_group");
      let queryString = "byRegistry[id]=" + value.id;
      Repo.index(queryString)
          .then((response) => {
            this.groups = response.data;
            this.form.request_input.gruppo = null;
            this.form.request_input.membri = [];
            this.showGroups = true;
          })
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({preset: "error", text: errMsg});
          });

      const Reporeg = RepositoryFactory.get("registry");
      let qs = "relations/byRegistry";
      Reporeg.show(this.form.request_input.id_registro, qs)
          .then((response) => {
            this.relations = response.data.data.registries;
            this.form.request_input.relazioni_registri = [];
            this.showRelations = true;
          })
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({preset: "error", text: errMsg});
          });
    },
  },
  created() {
    const path = "auth/";
    const resources = [];
    if (!this.hasLoadedResource("reports"))
      resources.push(
          this.$store
              .dispatch(`${path}reports`)
              .then(() => {
              })
              .catch((error) => {
                let errMsg = this.$getErrorMessage(error);
                this.$showSnackbar({preset: "error", text: errMsg});
              })
      );
    if (resources.length) {
      this.getResources(resources)
          .then(() => {
          })
          .catch((error) => {
            console.log("One or more promises crashed... :|");
            this.onFailure(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
    } else {
      this.isLoading = false;
    }
  },
  mounted() {
    this.form.report_id = this.reportId;
  },
};
</script>
