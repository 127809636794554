<template>
  <div class="mx-1">
    <validation-observer ref="observer" v-slot="{ handleSubmit, invalid }">
      <b-form @submit.prevent="handleSubmit(createReport)">
        <b-card>
          <b-card-text>
            <base-radio
              :name="beFormReport.format.label"
              vid="format"
              :label="beFormReport.format.label"
              v-model="form.format"
              :rules="{ required: true }"
              :options="allowedFormatOptions"
            />
            <base-radio
              name="nominativi_senza_polizze"
              vid="nominativi_senza_polizze"
              label="Stampa anche nominativi senza polizze attive"
              v-model="form.request_input.nominativi_senza_polizze"
              :options="[
                { value: 'Y', text: 'Si' },
                { value: 'N', text: 'No' },
              ]"
            />
            <base-radio
              name="includi_note"
              vid="includi_note"
              label="Includi note "
              v-model="form.request_input.includi_note"
              :options="[
                { value: 'Y', text: 'Si' },
                { value: 'N', text: 'No' },
              ]"
            />
            <base-select
              vid="formato_rubricli"
              name="formato_rubricli"
              label="Formato"
              v-model="form.request_input.formato_rubricli"
              :options="
                convertArrayToObject(
                  beFormReport.request_input.formato_rubricli.options
                )
              "
              :rules="{ required: true }"
            />
          </b-card-text>
          <b-button
            type="submit"
            :disabled="invalid"
            variant="outline-lisaweb"
            size="sm"
            class="float-right"
          >
            Salva
          </b-button>
        </b-card>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import BaseSelect from "@/components/form/BaseSelect";
import BaseRadio from "@/components/form/BaseRadio";
import common from "@/components/reports/common.vue";

export default {
  extends: common,
  data() {
    return {
      form: {
        format: null,
        request_input: {
          includi_note: null,
          nominativi_senza_polizze: null,
        },
      },
    };
  },
  components: {
    BaseSelect,
    BaseRadio,
  },
};
</script>
