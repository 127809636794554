<template>
  <div class="mx-1">
    <validation-observer ref="observer" v-slot="{ handleSubmit, invalid }">
      <b-form @submit.prevent="handleSubmit(createReport)">
        <b-card>
          <b-card-text>
            <div class="item-flex-wrapper">
              <base-select
                name="month"
                vid="month"
                label="Mese"
                v-model="form.request_input.mese"
                :options="mesi"
              />
              <base-select
                name="year"
                vid="year"
                label="Anno"
                v-model="form.request_input.anno"
                :options="anni"
              />
            </div>
            <base-datepicker
              name="printing_date"
              label="Data Stampa"
              v-model="form.request_input.data_stampa"
            />
            <base-select
              name="coass_companies"
              vid="coass_companies"
              label="Compagnia"
              v-model="form.request_input.compagnie_coass"
              :options="opt_insurer_participant"
              @input="onInputInsurerParticipants"
              :multiple="true"
              :closeOnSelect="false"
            />
          </b-card-text>
          <b-button
            type="submit"
            :disabled="invalid"
            variant="outline-lisaweb"
            size="sm"
            class="float-right"
          >
            Salva
          </b-button>
        </b-card>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import BaseSelect from "@/components/form/BaseSelect";
import common from "@/components/reports/common.vue";
import BaseDatepicker from "@/components/form/BaseDatepicker";
import { mapGetters } from "vuex";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import moment from "moment";
moment.locale("it");

export default {
  extends: common,
  data() {
    return {
      companies: [],
      opt_insurer_participant: null,
      primoAnno: 1990,
      anni: [],
      mesi: [
        { value: "01", text: "Gennaio" },
        { value: "02", text: "Febbraio" },
        { value: "03", text: "Marzo" },
        { value: "04", text: "Aprile" },
        { value: "05", text: "Maggio" },
        { value: "06", text: "Giugno" },
        { value: "07", text: "Luglio" },
        { value: "08", text: "Agosto" },
        { value: "09", text: "Settembre" },
        { value: "10", text: "Ottobre" },
        { value: "11", text: "Novembre" },
        { value: "12", text: "Dicembre" },
      ],
      form: {
        report_id: null,
        format: "pdf",
        request_input: {
          mese: null,
          anno: null,
          compagnie_coass: [],
          data_stampa: null,
        },
      },
    };
  },
  methods: {
    ...mapGetters("auth", {
      getInsurers: "insurers",
    }),
    onInputInsurerParticipants(val) {
      if (!val) {
        this.filter.byInsurerParticipant.id = "!0";
      }
    },
    arrayRange(start, stop, step) {
      return Array.from(
        { length: (stop - start) / step + 1 },
        (_, index) => start + index * step
      );
    },
    generateYears() {
      // array from current year down to primoAnno (eg: [2023, 2022, ..., 1990])
      let years = [];
      const current_year = moment().year();
      years = this.arrayRange(this.primoAnno, current_year, 1);

      return years
        .sort((a, b) => {
          return b - a;
        })
        .map((year) => {
          return { value: year, text: year };
        });
    },
  },
  components: {
    BaseSelect,
    BaseDatepicker,
  },
  mounted() {
    this.anni = this.generateYears();
    // this.companies = this.getInsurers();
    const Repo = RepositoryFactory.get("insurer_participant");
    Repo.index("perPage=0")
      .then((response) => {
        const data = response.data.data;
        let insurer_participants = data.map((e) => {
          return {
            value: e.id,
            text: `${e.code} - ${e.title}`,
            code: `${e.code}`,
          };
        });
        this.opt_insurer_participant = insurer_participants;
      })
      .catch((error) => {
        let errMsg = this.$getErrorMessage(error);
        this.$showSnackbar({ preset: "error", text: errMsg });
        this.isLoading = false;
      });
  },
};
</script>

<style>
.item-flex-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}
</style>
