<template>
  <div class="mx-1">
    <validation-observer ref="observer" v-slot="{ handleSubmit, invalid }">
      <b-form @submit.prevent="handleSubmit(createReport)">
        <b-card>
          <b-card-text>
            <base-radio
              name="format"
              vid="format"
              label="Formato"
              v-model="form.format"
              :options="allowedFormatOptions"
              :rules="{ required: true }"
            />
            <div>
              <b-button-toolbar>
                <b-button-group>
                  <base-input
                    name="Anagrafica"
                    label="Anagrafica"
                    v-model="registry_data"
                    :readonly="true"
                    :rules="{ required: true }"
                  />
                  <b-button
                    class="mt-4 btn-quick"
                    size="sm"
                    text="Button"
                    variant="lisaweb"
                    title="Ricerca Veloce"
                    @click="openQuickSearchRegistry"
                    ><b-icon icon="search"></b-icon
                  ></b-button>
                  <b-button
                    v-if="form.id_registro"
                    class="mt-4 btn-quick ml-1"
                    size="sm"
                    text="Button"
                    variant="lisaweb"
                    @click="resetRegistryId"
                    title="Elimina Anagrafica"
                    ><b-icon icon="trash"></b-icon
                  ></b-button>
                </b-button-group>
              </b-button-toolbar>
            </div>
            <base-radio
              name="includi_gruppo"
              vid="includi_gruppo"
              label="Includi Gruppo"
              v-if="registry_data"
              v-model="form.request_input.includi_gruppo"
              :options="[
                { value: 'Y', text: 'Attiva selezione per gruppo anagrafico' },
                {
                  value: 'N',
                  text: 'Attiva selezione in base alle relazioni anagrafiche',
                },
              ]"
              @select="svuotaValori()"
            />
            <b-card
              header="Gruppi"
              class="mt-2 mb-2"
              v-if="form.request_input.includi_gruppo === 'Y'"
            >
              <b-card-text>
                <base-select
                  vid="gruppo"
                  name="gruppo"
                  label="Gruppo"
                  :taggable="true"
                  v-model="form.request_input.gruppo"
                  :options="formatGroups()"
                  @select="mostraMembriGruppo()"
                />
                <base-select
                  vid="clienti"
                  name="clienti"
                  label="Cliente Associati al Gruppo"
                  v-model="form.request_input.membri"
                  :multiple="true"
                  :taggable="true"
                  :closeOnSelect="false"
                  :options="formatMembers()"
                />
                <b-button
                :pressed.sync="myToggleM"
                variant="light"
                class="mb-2"
                @click="myToggleM ? selectAllMembers() : deselectAllMembers()"
              >
                {{ myToggleM ? "Deseleziona tutti" : "Seleziona tutti" }}
              </b-button>
              </b-card-text>
            </b-card>
            <b-card
              header="Relazioni"
              class="mt-2 mb-2"
              v-if="form.request_input.includi_gruppo === 'N'"
            >
              <b-card-text>
                <base-select
                  vid="relazioni"
                  name="relazioni"
                  label="Relazioni"
                  v-model="form.request_input.relazioni_registri"
                  :multiple="true"
                  :taggable="true"
                  :options="relationOptions"
                />
                <b-button
                :pressed.sync="myToggleR"
                variant="light"
                class="mb-2"
                @click="
                  myToggleR ? selectAllRelations() : deselectAllRelations()
                "
                >{{ myToggleR ? "Deseleziona tutti" : "Seleziona tutti" }}
              </b-button>
              </b-card-text>
            </b-card>
          </b-card-text>
          <b-button
            type="submit"
            :disabled="invalid"
            variant="outline-lisaweb"
            size="sm"
            class="float-right"
          >
            Salva
          </b-button>
        </b-card>
        <quick-search-registry-modal
          ref="quickSearchRegistry"
          @input="handleRegistryModalInput"
        >
        </quick-search-registry-modal>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import BaseRadio from "@/components/form/BaseRadio";
import BaseSelect from "@/components/form/BaseSelect";
import BaseInput from "@/components/form/BaseInput";
import { toInfoData } from "@/utils/transforms";
import common from "@/components/reports/common.vue";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import QuickSearchRegistryModal from "@/components/modals/quickSearchRegistry";

export default {
  extends: common,
  data() {
    return {
      myToggleM: false,
      myToggleR: false,
      relations: [],
      relationOptions: [],
      registry_data: null,
      members: [],
      groups: [],
      showGroups: false,
      showMembers: false,
      showRelations: false,
      form: {
        format: null,
        request_input: {
          id_registro: null,
          data_da: null,
          data_a: null,
          includi_gruppo: null,
          relazioni: [],
          membri: [],
          gruppo: [],
          clienti: [],
          relazioni_registri: [],
        },
      },
    };
  },
  components: {
    BaseRadio,
    BaseSelect,
    QuickSearchRegistryModal,
    BaseInput,
  },
  methods: {
    toInfoData,
    svuotaValori() {
      console.log(this.form.request_input.includi_gruppo);
      if (this.form.request_input.includi_gruppo == "Y") {
        this.form.request_input.relazioni_registri = [];
      } else {
        this.form.request_input.gruppo = [];
        this.form.request_input.membri = [];
      }
    },
    formatMembers() {
      return this.members.map((registry) => {
        return {
          value: registry.id,
          text: registry.formatted_title,
        };
      });
    },
    mostraMembriGruppo() {
      this.groups.data.map((group) => {
        if (group.id == this.form.request_input.gruppo) {
          this.members = group.registries;
          this.showMembers = true;
        }
      });
    },
    formatGroups() {
      return this.groups.data.map((value) => {
        return {
          value: value.id,
          text: value.code,
        };
      });
    },
    formatRelations() {
      return this.relations.map((relation) => {
        return {
          value: relation.id,
          text: relation.formatted_title,
        };
      });
    },
    selectAllMembers() {
      this.form.request_input.membri = this.members.map((member) => member.id);
    },
    deselectAllMembers() {
      this.form.request_input.membri = [];
    },
    selectAllRelations() {
      this.form.request_input.relazioni_registri = this.relations.map(
        (relation) => relation.id
      );
    },
    deselectAllRelations() {
      this.form.request_input.relazioni_registri = [];
    },
    openQuickSearchRegistry() {
      this.form.request_input.id_registro = null;
      this.$bvModal.show("quickSearchRegistryModal");
    },
    onInputRegistry(value) {
      const registry = value;
      if (registry) {
        // Registry Groups
        if (registry.registry_groups.length) {
          this.registryGroupOptions = registry.registry_groups.map(
            (registry_group) => {
              return {
                value: registry_group.id,
                text: `${registry_group.code}-${registry_group.title}`,
              };
            }
          );
        } else {
          this.form.request_input.gruppo = [];
          this.registryGroupOptions = [];
        }
        // Relative Types
        if (registry.registries.length) {
          this.relationOptions = registry.registries.map((registry) => {
            return {
              value: registry.id,
              text:
                registry.status_registry.value === 1
                  ? `${registry.attributables.CREG}-${registry.pivot.relative_type.male}`
                  : registry.attributables.SEX === "M"
                  ? `${registry.attributables.SURN} ${registry.attributables.NAME} - ${registry.pivot.relative_type.male}`
                  : `${registry.attributables.SURN} ${registry.attributables.NAME} - ${registry.pivot.relative_type.female}`,
            };
          });
        } else {
          this.form.request_input.relazioni_registri = [];
          this.relationOptions = [];
        }
      }
    },
    handleRegistryModalInput(value) {
      this.onInputRegistry(value);
      this.form.request_input.id_registro = value.id;
      this.registry_data = this.toInfoData(value, "registryfullname");
      this.$bvModal.hide("quickSearchRegistryModal");
      const Repo = RepositoryFactory.get("registry_group");
      let queryString = "byRegistry[id]=" + value.id;
      Repo.index(queryString)
        .then((response) => {
          this.groups = response.data;
          this.form.request_input.gruppo = null;
          this.form.request_input.membri = [];
          this.showGroups = true;
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({ preset: "error", text: errMsg });
        });

      const Reporeg = RepositoryFactory.get("registry");
      let qs = "relations/byRegistry";
      Reporeg.show(this.form.request_input.id_registro, qs)
        .then((response) => {
          this.relations = response.data.data.registries;
          this.form.request_input.relazioni_registri = [];
          this.showRelations = true;
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({ preset: "error", text: errMsg });
        });
    },
  },
};
</script>
