<template>
  <div class="mx-1">
    <validation-observer ref="observer" v-slot="{ handleSubmit, invalid }">
      <b-form @submit.prevent="handleSubmit(createReport)">
        <b-card>
          <b-card-text>
            <base-radio
              :name="beFormReport.format.label"
              vid="format"
              :label="beFormReport.format.label"
              v-model="form.format"
              :options="allowedFormatOptions"
              :rules="{ required: true }"
            />
            <base-datepicker
              vid="data_da"
              name="data_da"
              label="Data di Riferimento"
              v-model="form.request_input.data_da"
              :rules="{ required: true }"
            />
            <base-select
              vid="produttori"
              name="produttori"
              label="Produttori"
              v-model="form.request_input.produttori"
              :options="getSalesmen()"
              :multiple="true"
              :taggable="salesmen_taggable"
              :closeOnSelect="false"
              :defaultHtmlMultipleSelect="true"
              @changeTaggable="changeTaggable"
              :rules="{ required: true }"
            />
            <b-button
              :pressed.sync="myToggleS"
              variant="light"
              class="mb-2"
              @click="myToggleS ? selectAllSalesmen() : deselectAllSalesmen()"
            >
              {{
                myToggleS ? "Deseleziona tutti" : "Seleziona tutti"
              }}</b-button
            >
            <base-select
              vid="formato_titultigi"
              name="formato_titultigi"
              label="Struttura"
              v-model="form.request_input.formato_titultigi"
              :options="
                convertArrayToObject(
                  beFormReport.request_input.formato_titultigi.options
                )
              "
              :rules="{ required: true }"
            />
          </b-card-text>
          <b-button
            type="submit"
            :disabled="invalid"
            variant="outline-lisaweb"
            size="sm"
            class="float-right"
          >
            Salva
          </b-button>
        </b-card>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import BaseSelect from "@/components/form/BaseSelect";
import BaseRadio from "@/components/form/BaseRadio";
import BaseDatepicker from "@/components/form/BaseDatepicker";
import { mapGetters } from "vuex";
import common from "@/components/reports/common.vue";

export default {
  extends: common,
  data() {
    return {
      myToggleS: false,
      salesmen_taggable: true,
      form: {
        format: null,
        report_id: this.reportId,
        request_input: {
          data_da: null,
          formato_titultigi: null,
          produttori: [],
        },
      },
    };
  },
  components: {
    BaseDatepicker,
    BaseSelect,
    BaseRadio,
  },
  methods: {
    changeTaggable(val) {
      this.salesmen_taggable = val;
    },
    selectAllSalesmen() {
      this.form.request_input.produttori = this.getSalesmen().map(
        (salesman) => salesman.value
      );
    },
    deselectAllSalesmen() {
      this.form.request_input.produttori = [];
    },
    ...mapGetters("auth", {
      getSalesmen: "salesmen",
    }),
  },
};
</script>
