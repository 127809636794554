<template>
  <div class="mx-1">
    <validation-observer ref="observer" v-slot="{ handleSubmit, invalid }">
      <b-form @submit.prevent="handleSubmit(createReport)">
        <b-card>
          <b-card-text>
            <base-radio
              vid="includi_note"
              name=""
              label="Includi Note"
              v-model="form.request_input.includi_note"
              :options="[
                { value: 'Y', text: 'Si' },
                { value: 'N', text: 'No' },
              ]"
            />
          </b-card-text>
          <b-button
            type="submit"
            :disabled="invalid"
            variant="outline-lisaweb"
            size="sm"
            class="float-right"
          >
            Salva
          </b-button>
        </b-card>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import BaseRadio from "@/components/form/BaseRadio";
import common from "@/components/reports/common.vue";

export default {
  extends: common,
  data() {
    return {
      form: {
        format: "pdf",
        request_input: {
          includi_note: null,
        },
      },
    };
  },
  components: {
    BaseRadio,
  },
  methods: {
    createReport() {
      this.$emit("saveReport", this.form);
    },
  },
};
</script>
