<template>
  <div class="mx-1">
    <validation-observer ref="observer" v-slot="{ handleSubmit, invalid }">
      <b-form @submit.prevent="handleSubmit(createReport)">
        <b-card>
          <b-card-text>
            <base-radio
              :name="beFormReport.format.label"
              vid="format"
              :label="beFormReport.format.label"
              v-model="form.format"
              :options="allowedFormatOptions"
              :rules="{ required: true }"
            />
            <base-datepicker
              vid="data_da"
              name="Data da"
              label="Data da"
              v-model="form.request_input.data_da"
            />
            <base-datepicker
              vid="data_a"
              name="Data a"
              label="Data a"
              v-model="form.request_input.data_a"
            />
            <base-select
              vid="compagnie"
              name="compagnie"
              label="Compagnie"
              v-model="form.request_input.compagnie"
              :taggable="true"
              :multiple="true"
              :closeOnSelect="false"
              :options="getInsurers()"
            />
            <b-button
              :pressed.sync="myToggleI"
              variant="light"
              class="mb-2"
              @click="myToggleI ? selectAllInsurers() : deselectAllInsurers()"
              >{{
                myToggleI ? "Deseleziona tutti" : "Seleziona tutti"
              }}</b-button
            >
            <base-select
              vid="produttori"
              name="produttori"
              label="Produttori"
              v-model="form.request_input.produttori"
              :options="getSalesmen()"
              :multiple="true"
              :taggable="salesmen_taggable"
              :closeOnSelect="false"
              :defaultHtmlMultipleSelect="true"
              @changeTaggable="changeTaggable"
            />
            <b-button
              :pressed.sync="myToggleS"
              variant="light"
              class="mb-2"
              @click="myToggleS ? selectAllSalesmen() : deselectAllSalesmen()"
            >
              {{
                myToggleS ? "Deseleziona tutti" : "Seleziona tutti"
              }}</b-button
            >
            <base-select
              vid="formato_renprod"
              name="formato_renprod"
              label="Versione Scheda"
              v-model="form.request_input.formato_renprod"
              :options="
                convertArrayToObject(
                  beFormReport.request_input.formato_renprod.options
                )
              "
              :rules="{ required: true }"
            />
          </b-card-text>
          <b-button
            type="submit"
            :disabled="invalid"
            variant="outline-lisaweb"
            size="sm"
            class="float-right"
          >
            Salva
          </b-button>
        </b-card>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import BaseSelect from "@/components/form/BaseSelect";
import BaseRadio from "@/components/form/BaseRadio";
import BaseDatepicker from "@/components/form/BaseDatepicker";
import { mapGetters } from "vuex";
import common from "@/components/reports/common.vue";

export default {
  extends: common,
  data() {
    return {
      myToggleS: false,
      myToggleI: false,
      salesmen_taggable: true,
      form: {
        format: null,
        request_input: {
          compagnie: [],
          data_da: null,
          data_a: null,
          produttori: [],
        },
      },
    };
  },
  components: {
    BaseSelect,
    BaseRadio,
    BaseDatepicker,
  },
  methods: {
    changeTaggable(val) {
      this.salesmen_taggable = val;
    },
    selectAllSalesmen() {
      this.form.request_input.produttori = this.getSalesmen().map(
        (salesman) => salesman.value
      );
    },
    deselectAllSalesmen() {
      this.form.request_input.produttori = [];
    },
    selectAllInsurers() {
      this.form.request_input.compagnie = this.getInsurers().map(
        (insurer) => insurer.value
      );
    },
    deselectAllInsurers() {
      this.form.request_input.compagnie = [];
    },
    ...mapGetters("auth", {
      getSalesmen: "salesmen",
      getInsurers: "insurers",
    }),
  },
};
</script>
