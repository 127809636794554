<template>
  <div class="mx-1">
    <validation-observer ref="observer" v-slot="{ handleSubmit, invalid }">
      <b-form @submit.prevent="handleSubmit(createReport)">
        <b-card>
          <b-card-text>
            <base-radio
              :name="beFormReport.format.label"
              vid="format"
              :label="beFormReport.format.label"
              v-model="form.format"
              :options="allowedFormatOptions"
              :rules="{ required: true }"
            />
            <base-select
            :vid="beFormReport.request_input.formato_repolex.label"
              :name="beFormReport.request_input.formato_repolex.label"
              :label="beFormReport.request_input.formato_repolex.label"
              v-model="form.request_input.formato_repolex"
              :options="
                convertArrayToObject(
                  beFormReport.request_input.formato_repolex.options
                )
              "
              :rules="{ required: true }"
            />
          </b-card-text>
          <b-button
            type="submit"
            :disabled="invalid"
            variant="outline-lisaweb"
            size="sm"
            class="float-right"
          >
            Salva
          </b-button>
        </b-card>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import BaseSelect from "@/components/form/BaseSelect";
import BaseRadio from "@/components/form/BaseRadio";
import common from "@/components/reports/common.vue";
// import { mapGetters } from "vuex";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import moment from "moment";
moment.locale("it");

export default {
  extends: common,
  data() {
    return {
      companies: [],
      opt_insurer_participant: null,
      form: {
        report_id: null,
        format: "pdf",
        request_input: {
          formato_repolex: null,
        },
      },
    };
  },
  methods: {
  },
  components: {
    BaseRadio,
    BaseSelect,
  },
  mounted() {
    this.anni = this.generateYears();
    // this.companies = this.getInsurers();
    const Repo = RepositoryFactory.get("insurer_participant");
    Repo.index("perPage=0")
      .then((response) => {
        const data = response.data.data;
        let insurer_participants = data.map((e) => {
          return {
            value: e.id,
            text: `${e.code} - ${e.title}`,
            code: `${e.code}`,
          };
        });
        this.opt_insurer_participant = insurer_participants;
      })
      .catch((error) => {
        let errMsg = this.$getErrorMessage(error);
        this.$showSnackbar({ preset: "error", text: errMsg });
        this.isLoading = false;
      });
  },
};
</script>

<style>
.item-flex-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}
</style>
