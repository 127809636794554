<template>
  <div class="mx-1">
    <validation-observer ref="observer" v-slot="{ handleSubmit, invalid }">
      <b-form @submit.prevent="handleSubmit(createReport)">
        <b-card>
          <b-card-text>
            <base-radio
              :name="beFormReport.format.label"
              vid="format"
              :label="beFormReport.format.label"
              v-model="form.format"
              :options="allowedFormatOptions"
              :rules="{ required: true }"
            />
            <base-select
              :vid="beFormReport.request_input.relazioni_combo.label"
              :name="beFormReport.request_input.relazioni_combo.label"
              :label="beFormReport.request_input.relazioni_combo.label"
              v-model="form.request_input.relazioni_combo"
              :options="
                convertArrayToObject(
                  beFormReport.request_input.relazioni_combo.options
                )
              "
              :rules="{ required: true }"
            />
            <base-select
              vid="compagnie"
              name="compagnie"
              label="Compagnia"
              v-model="form.request_input.compagnie"
              :taggable="true"
              @select="onChangeInsurer()"
              :options="getInsurers()"
              @remove="onChangeInsurer()"
              :multiple="true"
              :required="true"
              :closeOnSelect="false"
              :rules="{ required: true }"
            />
            <b-button
              :pressed.sync="myToggleI"
              :disabled="insurersDisabled"
              variant="light"
              class="mb-2"
              @click="myToggleI ? selectAllInsurers() : deselectAllInsurers()"
              >{{
                myToggleI ? "Deseleziona tutti" : "Seleziona tutti"
              }}</b-button
            >
            <base-select
              vid="ramo"
              name="ramo"
              label="Ramo"
              :taggable="true"
              v-model="form.request_input.rami"
              :options="risk_branches"
              :multiple="true"
              @select="onSelectBranch()"
              @remove="onRemoveBranch()"
              :closeOnSelect="false"
              :rules="{ required: true }"
            />
            <b-button
              :pressed.sync="myToggleB"
              :disabled="insurersDisabled"
              variant="light"
              class="mb-2"
              @click="myToggleB ? selectAllBranches() : deselectAllBranches()"
              >{{
                myToggleB ? "Deseleziona tutti" : "Seleziona tutti"
              }}</b-button
            >
            <base-select
              vid="prodotto"
              name="prodotto"
              label="Prodotto"
              v-model="form.request_input.prodotti"
              :options="products"
              :disabled="!canSelectProducts || insurersDisabled"
              :multiple="true"
              :taggable="true"
              :closeOnSelect="false"
            />
            <b-button
              :pressed.sync="myToggleP"
              :disabled="!canSelectProducts || insurersDisabled"
              variant="light"
              class="mb-2"
              @click="myToggleP ? selectAllProducts() : deselectAllProducts()"
              >{{
                myToggleP ? "Deseleziona tutti" : "Seleziona tutti"
              }}</b-button
            >
            <base-select
              :vid="beFormReport.request_input.tipo_richiesta.label"
              :name="beFormReport.request_input.tipo_richiesta.label"
              :label="beFormReport.request_input.tipo_richiesta.label"
              v-model="form.request_input.tipo_richiesta"
              :options="
                convertArrayToObject(
                  beFormReport.request_input.tipo_richiesta.options
                )
              "
              :rules="{ required: true }"
            />
          </b-card-text>
          <b-button
            type="submit"
            :disabled="invalid"
            variant="outline-lisaweb"
            size="sm"
            class="float-right"
          >
            Salva
          </b-button>
        </b-card>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import BaseSelect from "@/components/form/BaseSelect";
import StorageGetterMixin from "@/mixins/StorageGetterMixin";
import { mapGetters } from "vuex";
import moment from "moment";
import common from "@/components/reports/common.vue";
import BaseRadio from "@/components/form/BaseRadio";
moment.locale("it");

export default {
  extends: common,
  data() {
    return {
      myToggleI: false,
      myToggleB: false,
      myToggleP: false,
      insurersDisabled: false,
      form: {
        report_id: null,
        format: null,
        request_input: {
          relazioni_combo: null,
          tipo_richiesta: null,
          compagnie: [],
          rami: [],
          prodotti: [],
        },
      },
      insurers: [],
      risk_branches: [],
      products: [],
      canSelectBranch: false,
      canSelectProducts: false,
    };
  },
  mixins: [StorageGetterMixin],
  components: {
    BaseRadio,
    BaseSelect,
  },
  methods: {
    // override default
    createReport() {
      this.$emit("saveReport", this.form);
    },
    ...mapGetters("auth", {
      getInsurers: "insurers",
      getProducts: "products",
      getBranches: "branches",
    }),
    onChangeInsurer() {
      this.risk_branches = this.getBranches()(
        this.form.request_input.compagnie
      );
      this.canSelectBranch = true;
      if (!this.form.request_input.compagnie.length == 1) {
        this.form.request_input.prodotti = [];
        this.products = [];
        this.canSelectProducts = false;
      }
    },
    onSelectBranch() {
      this.products = this.getProducts()(
          this.form.request_input.compagnie,
          this.form.request_input.rami,
          null
      );
      this.canSelectProducts = this.form.request_input.compagnie.length === 1 ? true : false;
    },
    selectAllInsurers() {
      this.form.request_input.compagnie = this.getInsurers().map(
        (insurer) => insurer.value
      );
      this.renderAllBranches();
    },
    deselectAllInsurers() {
      this.form.request_input.compagnie = [];
      this.form.request_input.rami = [];
      this.form.request_input.prodotti = [];
    },
    renderAllBranches() {
      if (this.form.request_input.compagnie.length === 0) return;
      this.risk_branches = this.getBranches()();
    },
    selectAllBranches() {
      this.form.request_input.rami = this.risk_branches.map(
        (branch) => branch.value
      );
      this.onSelectBranch();
    },
    deselectAllBranches() {
      this.form.request_input.rami = [];
      this.form.request_input.prodotti = [];
    },
    selectAllProducts() {
      this.form.request_input.prodotti = this.products.map(
        (branch) => branch.value
      );
    },
    deselectAllProducts() {
      this.form.request_input.prodotti = [];
    },
    onRemoveBranch() {
      if (this.form.request_input.rami.length == 0) {
        this.canSelectProducts = false;
      } else {
        this.products = this.getProducts()(
          this.form.request_input.compagnie[0],
          this.form.request_input.rami,
          1
        );
      }
    },
  },
  created() {
    /* */
    console.log("created REPONUCL");
    const path = "auth/";
    const resources = [];
    if (!this.hasLoadedResource("reports"))
      resources.push(
        this.$store
          .dispatch(`${path}reports`)
          .then(() => {})
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          })
      );
    if (!this.hasLoadedResource("cooperators"))
      resources.push(
        this.$store
          .dispatch(`${path}cooperators`)
          .then(() => {})
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          })
      );
    if (resources.length) {
      this.getResources(resources)
        .then(() => {})
        .catch((error) => {
          console.log("One or more promises crashed... :|");
          this.onFailure(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    } else {
      this.isLoading = false;
    }
    /* */
  },
  computed: {
    reportId() {
      return this.$store.state.auth.reports.find(
        (e) => e.code === this.reportCode
      ).id;
    },
  },
  mounted() {
    this.form.report_id = this.reportId;
    this.risk_branches = this.getBranches()();
  },
};
</script>
