var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mx-1"},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit, invalid }){return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.createReport)}}},[_c('b-card',[_c('b-card-text',[_c('base-radio',{attrs:{"name":_vm.beFormReport.format.label,"vid":"format","label":_vm.beFormReport.format.label,"rules":{ required: true },"options":_vm.allowedFormatOptions},model:{value:(_vm.form.format),callback:function ($$v) {_vm.$set(_vm.form, "format", $$v)},expression:"form.format"}}),_vm._v(" "),_c('base-radio',{attrs:{"name":"nominativi_senza_polizze","vid":"nominativi_senza_polizze","label":"Stampa anche nominativi senza polizze attive","options":[
              { value: 'Y', text: 'Si' },
              { value: 'N', text: 'No' },
            ]},model:{value:(_vm.form.request_input.nominativi_senza_polizze),callback:function ($$v) {_vm.$set(_vm.form.request_input, "nominativi_senza_polizze", $$v)},expression:"form.request_input.nominativi_senza_polizze"}}),_vm._v(" "),_c('base-radio',{attrs:{"name":"includi_note","vid":"includi_note","label":"Includi note ","options":[
              { value: 'Y', text: 'Si' },
              { value: 'N', text: 'No' },
            ]},model:{value:(_vm.form.request_input.includi_note),callback:function ($$v) {_vm.$set(_vm.form.request_input, "includi_note", $$v)},expression:"form.request_input.includi_note"}}),_vm._v(" "),_c('base-select',{attrs:{"vid":"formato_rubricli","name":"formato_rubricli","label":"Formato","options":_vm.convertArrayToObject(
                _vm.beFormReport.request_input.formato_rubricli.options
              ),"rules":{ required: true }},model:{value:(_vm.form.request_input.formato_rubricli),callback:function ($$v) {_vm.$set(_vm.form.request_input, "formato_rubricli", $$v)},expression:"form.request_input.formato_rubricli"}})],1),_vm._v(" "),_c('b-button',{staticClass:"float-right",attrs:{"type":"submit","disabled":invalid,"variant":"outline-lisaweb","size":"sm"}},[_vm._v("\n          Salva\n        ")])],1)],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }