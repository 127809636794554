<template>
  <div class="mx-1">
    <validation-observer ref="observer" v-slot="{ handleSubmit, invalid }">
      <b-form @submit.prevent="handleSubmit(createReport)">
        <b-card>
          <b-card-text>
            <base-radio
              :name="beFormReport.format.label"
              vid="format"
              :label="beFormReport.format.label"
              v-model="form.format"
              :options="allowedFormatOptions"
              :rules="{ required: true }"
            />
            <base-datepicker
              vid="data_da"
              name="Data da"
              label="Data da"
              v-model="form.request_input.data_da"
              :rules="{ required: true }"
            />
            <base-datepicker
              vid="data_a"
              name="Data a"
              label="Data a"
              v-model="form.request_input.data_a"
              :rules="{ required: true }"
            />
            <base-select
              vid="compagnie"
              name="compagnie"
              label="Compagnie"
              v-model="form.request_input.compagnie"
              :options="getInsurers()"
              :taggable="true"
              :multiple="true"
              :closeOnSelect="false"
              :rules="{ required: true }"
            />
            <b-button
              :pressed.sync="myToggleS"
              variant="light"
              class="mb-2"
              @click="myToggleS ? selectAllInsurers() : deselectAllInsurers()"
            >
              {{
                myToggleS ? "Deseleziona tutti" : "Seleziona tutti"
              }}</b-button
            >
          </b-card-text>
          <b-button
            type="submit"
            :disabled="invalid"
            variant="outline-lisaweb"
            size="sm"
            class="float-right"
          >
            Salva
          </b-button>
        </b-card>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import BaseSelect from "@/components/form/BaseSelect";
import BaseRadio from "@/components/form/BaseRadio";
import BaseDatepicker from "@/components/form/BaseDatepicker";
import { mapGetters } from "vuex";
import common from "@/components/reports/common.vue";

export default {
  extends: common,
  data() {
    return {
      myToggleS: false,
      form: {
        format: null,
        report_id: this.reportId,
        request_input: {
          compagnie: [],
          data_a: null,
          data_da: null,
        },
      },
    };
  },
  components: {
    BaseSelect,
    BaseDatepicker,
    BaseRadio,
  },
  methods: {
    ...mapGetters("auth", {
      getInsurers: "insurers",
    }),
    selectAllInsurers() {
      this.form.request_input.compagnie = this.getInsurers().map((c) => c.value);
    },
    deselectAllInsurers() {
      this.form.request_input.compagnie = []
    },
  },
};
</script>
