<template>
  <div class="mx-1">
    <validation-observer ref="observer" v-slot="{ handleSubmit, invalid }">
      <b-form @submit.prevent="handleSubmit(createReport)">
        <b-card>
          <b-card-text>
            <base-radio
              :name="beFormReport.format.label"
              vid="format"
              :label="beFormReport.format.label"
              v-model="form.format"
              :options="allowedFormatOptions"
              :rules="{ required: true }"
            />
            <base-datepicker
              vid="data_da"
              name="Data da"
              label="Data da"
              v-model="form.request_input.data_da"
              :rules="{ required: true }"
            />
            <base-datepicker
              vid="data_a"
              name="Data a"
              label="Data a"
              v-model="form.request_input.data_a"
              :rules="{ required: true }"
            />
            <base-select
              vid="compagnie"
              name="compagnie"
              label="Compagnie"
              v-model="form.request_input.compagnie"
              :options="getInsurers()"
              :taggable="true"
              :multiple="true"
              :closeOnSelect="false"
              :rules="{ required: true }"
            />
            <b-button
              :pressed.sync="myToggleI"
              variant="light"
              class="mb-2"
              @click="myToggleI ? selectAllInsurers() : deselectAllInsurers()"
              >{{
                myToggleI ? "Deseleziona tutti" : "Seleziona tutti"
              }}</b-button
            >
            <base-select
              vid="rami"
              name="rami"
              label="Rami"
              v-model="form.request_input.rami"
              :taggable="true"
              :multiple="true"
              :options="getBranches()()"
              :closeOnSelect="false"
              :rules="{ required: true }"
            />
            <b-button
              :pressed.sync="myToggleB"
              variant="light"
              class="mb-2"
              @click="myToggleB ? selectAllBranches() : deselectAllBranches()"
            >
              {{
                myToggleB ? "Deseleziona tutti" : "Seleziona tutti"
              }}</b-button
            >
            <base-select
              vid="produttori"
              name="produttori"
              label="Produttori"
              v-model="form.request_input.produttori"
              :taggable="salesmen_taggable"
              :multiple="true"
              :closeOnSelect="false"
              :defaultHtmlMultipleSelect="true"
              @changeTaggable="changeTaggable"
              :options="getSalesmen()"
              :rules="{ required: true }"
            />
            <b-button
              :pressed.sync="myToggleS"
              variant="light"
              class="mb-2"
              @click="myToggleS ? selectAllSalesmen() : deselectAllSalesmen()"
            >
              {{
                myToggleS ? "Deseleziona tutti" : "Seleziona tutti"
              }}</b-button
            >
            <base-select
              vid="formato_incacol"
              name="formato_incacol"
              label="Livello Dettaglio"
              v-model="form.request_input.formato_incacol"
              :options="
                convertArrayToObject(
                  beFormReport.request_input.formato_incacol.options
                )
              "
              :rules="{ required: true }"
            />
            <base-select
              vid="formato_premi_provv"
              name="formato_premi_provv"
              label="Visualizza Importi"
              v-model="form.request_input.formato_premi_provv"
              :options="
                convertArrayToObject(
                  beFormReport.request_input.formato_premi_provv.options
                )
              "
              :rules="{ required: true }"
            />
          </b-card-text>
          <b-button
            type="submit"
            :disabled="invalid"
            variant="outline-lisaweb"
            size="sm"
            class="float-right"
          >
            Salva
          </b-button>
        </b-card>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import BaseSelect from "@/components/form/BaseSelect";
import BaseRadio from "@/components/form/BaseRadio";
import BaseDatepicker from "@/components/form/BaseDatepicker";
import StorageGetterMixin from "@/mixins/StorageGetterMixin";
import { mapGetters } from "vuex";
import common from "@/components/reports/common.vue";

export default {
  extends: common,
  mixins: [StorageGetterMixin],
  data() {
    return {
      form: {
        format: null,
        request_input: {
          compagnie: [],
          data_a: null,
          data_da: null,
          formato_incacol: null,
          formato_premi_provv: null,
          produttori: [],
          rami: [],
        },
      },
      myToggleS: false,
      myToggleI: false,
      salesmen_taggable: true,
      myToggleB: false,
    };
  },
  components: {
    BaseSelect,
    BaseDatepicker,
    BaseRadio,
  },
  methods: {
    changeTaggable(val) {
      this.salesmen_taggable = val;
    },
    selectAllSalesmen() {
      this.form.request_input.produttori = this.getSalesmen().map(
        (salesman) => salesman.value
      );
    },
    deselectAllSalesmen() {
      this.form.request_input.produttori = [];
    },
    selectAllBranches() {
      this.form.request_input.rami = this.getBranches()().map(
        (branch) => branch.value
      );
    },
    deselectAllBranches() {
      this.form.request_input.rami = [];
    },
    selectAllInsurers() {
      this.form.request_input.compagnie = this.getInsurers().map(
        (insurer) => insurer.value
      );
    },
    deselectAllInsurers() {
      this.form.request_input.compagnie = [];
    },
    ...mapGetters("auth", {
      getSalesmen: "salesmen",
      getInsurers: "insurers",
      getBranches: "branches",
    }),
  },
  created() {
    /* */
    console.log("created INCACOL");
    const path = "auth/";
    const resources = [];
    if (!this.hasLoadedResource("cooperators"))
      resources.push(
        this.$store
          .dispatch(`${path}cooperators`)
          .then(() => {})
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          })
      );
    if (resources.length) {
      this.getResources(resources)
        .then(() => {})
        .catch((error) => {
          console.log("One or more promises crashed... :|");
          this.onFailure(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    } else {
      this.isLoading = false;
    }
    /* */
  },
};
</script>
