<template>
  <b-modal
    :id="code"
    size="lg"
    hide-footer
    header-bg-variant="lisaweb"
    no-close-on-esc
    no-close-on-backdrop
  >
    <template #modal-header="{ cancel }">
      <div slot="modal-title" class="modal-title">
        <b-icon icon="clipboard"></b-icon>
        <span>Crea Report - {{ code }}</span>
      </div>
      <button
        type="button"
        class="close"
        title="Chiudi"
        data-dismiss="modal"
        @click="cancel(), close()"
      >
        &times;
      </button>
    </template>
    <b-overlay center :show="isLoading" rounded="sm">
    <polclirel
      v-if="code === 'POLCLIREL'"
      :reportCode="code"
      :beFormReport="beFormCreate"
      :beRulesReport="beRulesCreate"
      :allowedFormatOptions="allowedFormatOptions"
      @saveReport="save"
    >
    </polclirel>
    <monuprod
      v-if="code === 'MONUPROD'"
      :reportCode="code"
      :reportId="reportId"
      @saveReport="save"
      :beFormReport="beFormCreate"
      :beRulesReport="beRulesCreate"
      :allowedFormatOptions="allowedFormatOptions"
    >
    </monuprod>
    <rubricli
      v-if="code === 'RUBRICLI'"
      :reportCode="code"
      @saveReport="save"
      :beFormReport="beFormCreate"
      :beRulesReport="beRulesCreate"
      :allowedFormatOptions="allowedFormatOptions"
    >
    </rubricli>
    <polcligru
      v-if="code === 'POLCLIGRU'"
      :reportCode="code"
      @saveReport="save"
      :beFormReport="beFormCreate"
      :beRulesReport="beRulesCreate"
      :allowedFormatOptions="allowedFormatOptions"
    >
    </polcligru>
    <scaligru
      v-if="code === 'SCACLIGRU'"
      :reportCode="code"
      @saveReport="save"
      :beFormReport="beFormCreate"
      :beRulesReport="beRulesCreate"
      :allowedFormatOptions="allowedFormatOptions"
    >
    </scaligru>
    <portatti
      v-if="code === 'PORTATTI'"
      :reportCode="code"
      @saveReport="save"
      :beFormReport="beFormCreate"
      :beRulesReport="beRulesCreate"
      :allowedFormatOptions="allowedFormatOptions"
    >
    </portatti>
    <rubrigru
      v-if="code === 'RUBRIGRU'"
      :reportCode="code"
      @saveReport="save"
      :beFormReport="beFormCreate"
      :beRulesReport="beRulesCreate"
      :allowedFormatOptions="allowedFormatOptions"
    >
    </rubrigru>
    <lispol
      v-if="code === 'LISPOL'"
      :reportCode="code"
      @saveReport="save"
      :beFormReport="beFormCreate"
      :beRulesReport="beRulesCreate"
      :allowedFormatOptions="allowedFormatOptions"
    >
    </lispol>
    <cominca
      v-if="code === 'COMINCA'"
      :reportCode="code"
      @saveReport="save"
      :reportId="reportId"
      :beFormReport="beFormCreate"
      :beRulesReport="beRulesCreate"
      :allowedFormatOptions="allowedFormatOptions"
    >
    </cominca>
    <rencomp
      v-if="code === 'RENCOMP'"
      :reportCode="code"
      @saveReport="save"
      :beFormReport="beFormCreate"
      :beRulesReport="beRulesCreate"
      :allowedFormatOptions="allowedFormatOptions"
    >
    </rencomp>
    <renprod
      v-if="code === 'RENPROD'"
      :reportCode="code"
      @saveReport="save"
      :beFormReport="beFormCreate"
      :beRulesReport="beRulesCreate"
      :allowedFormatOptions="allowedFormatOptions"
    >
    </renprod>
    <incacomp
      v-if="code === 'INCACOMP'"
      :reportCode="code"
      @saveReport="save"
      :beFormReport="beFormCreate"
      :beRulesReport="beRulesCreate"
      :allowedFormatOptions="allowedFormatOptions"
    >
    </incacomp>
    <incacol
      v-if="code === 'INCACOL'"
      :reportCode="code"
      @saveReport="save"
      :beFormReport="beFormCreate"
      :beRulesReport="beRulesCreate"
      :allowedFormatOptions="allowedFormatOptions"
    >
    </incacol>
    <incaram
      v-if="code === 'INCARAM'"
      :reportCode="code"
      @saveReport="save"
      :beFormReport="beFormCreate"
      :beRulesReport="beRulesCreate"
      :allowedFormatOptions="allowedFormatOptions"
    >
    </incaram>
    <regifoca
      v-if="code === 'REGIFOCA'"
      :reportCode="code"
      @saveReport="save"
      :beFormReport="beFormCreate"
      :beRulesReport="beRulesCreate"
      :allowedFormatOptions="allowedFormatOptions"
    >
    </regifoca>
    <focaletacc
      v-if="code === 'FOCALETACC'"
      :reportCode="code"
      @saveReport="save"
      :beFormReport="beFormCreate"
      :beRulesReport="beRulesCreate"
      :allowedFormatOptions="allowedFormatOptions"
    >
    </focaletacc>
    <coasses
      v-if="code === 'COASSES'"
      :reportCode="code"
      @saveReport="save"
      :beFormReport="beFormCreate"
      :beRulesReport="beRulesCreate"
      :allowedFormatOptions="allowedFormatOptions"
    >
    </coasses>
    <totafoca
      v-if="code === 'TOTAFOCA'"
      :reportCode="code"
      @saveReport="save"
      :beFormReport="beFormCreate"
      :beRulesReport="beRulesCreate"
      :allowedFormatOptions="allowedFormatOptions"
    >
    </totafoca>
    <focacol
      v-if="code === 'FOCACOL'"
      :reportCode="code"
      @saveReport="save"
      :beFormReport="beFormCreate"
      :beRulesReport="beRulesCreate"
      :allowedFormatOptions="allowedFormatOptions"
    >
    </focacol>
    <incapaga
      v-if="code === 'INCAPAGA'"
      :reportCode="code"
      @saveReport="save"
      :reportId="reportId"
      :beFormReport="beFormCreate"
      :beRulesReport="beRulesCreate"
      :allowedFormatOptions="allowedFormatOptions"
    >
    </incapaga>
    <incaramti
      v-if="code === 'INCARAMTI'"
      :reportCode="code"
      @saveReport="save"
      :beFormReport="beFormCreate"
      :beRulesReport="beRulesCreate"
      :allowedFormatOptions="allowedFormatOptions"
    >
    </incaramti>
    <incacligru
      v-if="code === 'INCACLIGRU'"
      :reportCode="code"
      @saveReport="save"
      :beFormReport="beFormCreate"
      :beRulesReport="beRulesCreate"
      :allowedFormatOptions="allowedFormatOptions"
    >
    </incacligru>
    <incaprod
      v-if="code === 'INCAPROD'"
      :reportCode="code"
      @saveReport="save"
      :beFormReport="beFormCreate"
      :beRulesReport="beRulesCreate"
      :allowedFormatOptions="allowedFormatOptions"
    >
    </incaprod>
    <totasosp
      v-if="code === 'TOTASOSP'"
      :reportCode="code"
      @saveReport="save"
      :beFormReport="beFormCreate"
      :beRulesReport="beRulesCreate"
      :allowedFormatOptions="allowedFormatOptions"
    >
    </totasosp>
    <listit
      v-if="code === 'LISTIT'"
      :reportCode="code"
      @saveReport="save"
      :beFormReport="beFormCreate"
      :beRulesReport="beRulesCreate"
      :allowedFormatOptions="allowedFormatOptions"
    >
    </listit>
    <listitinca
      v-if="code === 'LISTITINCA'"
      :reportCode="code"
      @saveReport="save"
      :beFormReport="beFormCreate"
      :beRulesReport="beRulesCreate"
      :allowedFormatOptions="allowedFormatOptions"
    >
    </listitinca>
    <titultigi
      v-if="code === 'TITULTIGI'"
      :reportCode="code"
      @saveReport="save"
      :reportId="reportId"
      :beFormReport="beFormCreate"
      :beRulesReport="beRulesCreate"
      :allowedFormatOptions="allowedFormatOptions"
    >
    </titultigi>
    <titincacli
      v-if="code === 'TITINCACLI'"
      :reportCode="code"
      @saveReport="save"
      :beFormReport="beFormCreate"
      :beRulesReport="beRulesCreate"
      :allowedFormatOptions="allowedFormatOptions"
      @isLoadingP="isLoadingFunction"
    >
    </titincacli>
    <recatiin
      v-if="code === 'RECATIIN'"
      :reportCode="code"
      @saveReport="save"
      :beFormReport="beFormCreate"
      :beRulesReport="beRulesCreate"
      :allowedFormatOptions="allowedFormatOptions"
    >
    </recatiin>
    <redetiin
      v-if="code === 'REDETIIN'"
      :reportCode="code"
      @saveReport="save"
      :beFormReport="beFormCreate"
      :beRulesReport="beRulesCreate"
      :allowedFormatOptions="allowedFormatOptions"
    >
    </redetiin>
    <sinprecli
      v-if="code === 'SINPRECLI'"
      :reportCode="code"
      @saveReport="save"
      :beFormReport="beFormCreate"
      :beRulesReport="beRulesCreate"
      :allowedFormatOptions="allowedFormatOptions"
    >
    </sinprecli>
    <aggrecli
      v-if="code === 'AGGRECLI'"
      :reportCode="code"
      @saveReport="save"
      :reportId="reportId"
      :beFormReport="beFormCreate"
      :beRulesReport="beRulesCreate"
      :allowedFormatOptions="allowedFormatOptions"
    >
    </aggrecli>
    <incacomp2
      reportCode="INCACOMP2"
      v-if="code === 'INCACOMP2'"
      :reportId="reportId"
      @saveReport="save"
    >
    </incacomp2>
    <repapp
      reportCode="REPAPP"
      v-if="code === 'REPAPP'"
      :reportId="reportId"
      @saveReport="save"
    >
    </repapp>
    <repatt
      reportCode="REPATT"
      v-if="code === 'REPATT'"
      :reportId="reportId"
      @saveReport="save"
    >
    </repatt>
    <rencollab
      reportCode="RENCOLLAB"
      v-if="code === 'RENCOLLAB'"
      :beFormReport="beFormCreate"
      :beRulesReport="beRulesCreate"
      @saveReport="save"
    >
    </rencollab>
    <reponucl
      v-if="code === 'REPONUCL'"
      :reportCode="code"
      @saveReport="save"
      :beFormReport="beFormCreate"
      :beRulesReport="beRulesCreate"
      :allowedFormatOptions="allowedFormatOptions"
    >
    </reponucl>
    <repolex
      v-if="code === 'REPOLEX'"
      :reportCode="code"
      @saveReport="save"
      :beFormReport="beFormCreate"
      :beRulesReport="beRulesCreate"
      :allowedFormatOptions="allowedFormatOptions"
    >
    </repolex>
    <!-- Loader -->
    <template #overlay>
      <div class="text-center mt-5">
        <base-icon name="loading" width="35" height="35" />
        <p id="cancel-label">Operazione in corso...</p>
      </div>
    </template>
    </b-overlay>    
  </b-modal>
</template>
<script>
import FormMixin from "@/mixins/FormMixin";
// @/components/wallet/generated-reports/reports/ -> @/components/reports/
import polclirel from "@/components/reports/POLCLIREL";
import monuprod from "@/components/reports/MONUPROD";
import coasses from "@/components/reports/COASSES";
import rubricli from "@/components/reports/RUBRICLI";
import polcligru from "@/components/reports/POLCLIGRU";
import scaligru from "@/components/reports/SCACLIGRU";
import portatti from "@/components/reports/PORTATTI";
import rubrigru from "@/components/reports/RUBRIGRU";
import lispol from "@/components/reports/LISPOL";
import cominca from "@/components/reports/COMINCA";
import rencomp from "@/components/reports/RENCOMP";
import renprod from "@/components/reports/RENPROD";
import incacomp from "@/components/reports/INCACOMP";
import incacol from "@/components/reports/INCACOL";
import reponucl from "@/components/reports/REPONUCL";
import incaram from "@/components/reports/INCARAM";
import regifoca from "@/components/reports/REGIFOCA";
import focaletacc from "@/components/reports/FOCALETACC";
import totafoca from "@/components/reports/TOTAFOCA";
import focacol from "@/components/reports/FOCACOL";
import incapaga from "@/components/reports/INCAPAGA";
import incaramti from "@/components/reports/INCARAMTI";
import incacligru from "@/components/reports/INCACLIGRU";
import incaprod from "@/components/reports/INCAPROD";
import totasosp from "@/components/reports/TOTASOSP";
import listit from "@/components/reports/LISTIT";
import listitinca from "@/components/reports/LISTITINCA";
import titultigi from "@/components/reports/TITULTIGI";
import titincacli from "@/components/reports/TITINCACLI";
import recatiin from "@/components/reports/RECATIIN";
import sinprecli from "@/components/reports/SINPRECLI";
import redetiin from "@/components/reports/REDETIIN";
import aggrecli from "@/components/reports/AGGRECLI";
import incacomp2 from "@/components/reports/INCACOMP2";
import repapp from "@/components/reports/REPAPP";
import repatt from "@/components/reports/REPATT";
import rencollab from "@/components/reports/RENCOLLAB";
import repolex from "@/components/reports/REPOLEX";
import BaseIcon from "@/components/BaseIcon";

export default {
  mixins: [FormMixin],
  data() {
    return {
      isLoading: false
    };
  },
  components: {
    aggrecli,
    cominca,
    coasses,
    focacol,
    incacligru,
    incacol,
    incacomp,
    incacomp2,
    incapaga,
    incaprod,
    incaram,
    incaramti,
    lispol,
    listit,
    listitinca,
    monuprod,
    polclirel,
    polcligru,
    portatti,
    recatiin,
    redetiin,
    regifoca,
    focaletacc,
    rencollab,
    rencomp,
    renprod,
    repapp,
    repatt,
    reponucl,
    rubricli,
    rubrigru,
    sinprecli,
    scaligru,
    titincacli,
    titultigi,
    totafoca,
    totasosp,
    repolex,
    BaseIcon
  },
  methods: {
    isLoadingFunction(param) {
      console.log(param);
      this.isLoading = param;
    },
    close() {
      this.resetForm();
    },
    save(form) {
      this.$emit("save-report", this.code, form);
    },
  },
  props: {
    beFormCreate: {
      type: Object,
      default() {
        return {};
      },
    },
    beRulesCreate: {
      type: Object,
      default() {
        return {};
      },
    },
    code: {
      type: String,
      default() {
        return "";
      },
    },
    allowedFormatOptions: Array,
    reportId: Number, // REVIEW: seems it's unused, remove here and from instances
    // id: String, // never used
  },
};
</script>
<style lang="scss" scoped></style>
