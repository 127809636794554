<template>
  <div class="mx-1">
    <validation-observer ref="observer" v-slot="{ handleSubmit, invalid }">
      <b-form @submit.prevent="handleSubmit(createReportCustom)">
        <b-card>
          <b-card-text>
            <base-radio
              :name="beFormReport.format.label"
              vid="format"
              :label="beFormReport.format.label"
              v-model="form.format"
              :options="allowedFormatOptions"
              :rules="{ required: true }"
            />
            <base-month-picker
              vid="mese_da"
              name="mese_da"
              label="Incassi dal mese"
              v-model="form.request_input.mese_da"
              :clearable="true"
              :rules="{ required: true }"
              :monthOnly="isMonthOnly"
            />
            <base-month-picker
              vid="mese_a"
              name="mese_a"
              label="Incassi al mese"
              v-model="form.request_input.mese_a"
              :clearable="true"
              :rules="{ required: true }"
              :monthOnly="isMonthOnly"
            />
            <base-input
              vid="anno"
              name="anno"
              label="Incassi anno"
              v-model="form.request_input.anno"
              :placeholder="beFormReport.request_input.anno.label"
              type="number"
              maxlength="4"
              :rules="{ required: true, between: { min: '1900', max: '2050' } }"
            />
            <base-select
              vid="produttori"
              name="produttori"
              label="Produttori"
              v-model="form.request_input.produttori"
              :multiple="true"
              :taggable="salesmen_taggable"
              :closeOnSelect="false"
              :defaultHtmlMultipleSelect="true"
              @changeTaggable="changeTaggable"
              :rules="{ required: true }"
              :options="getSalesmen()"
            />
            <b-button
              :pressed.sync="myToggleS"
              variant="light"
              class="mb-2"
              @click="myToggleS ? selectAllSalesmen() : deselectAllSalesmen()"
            >
              {{
                myToggleS ? "Deseleziona tutti" : "Seleziona tutti"
              }}</b-button
            >
            <base-select
              vid="tipi_rischio"
              name="tipi_rischio"
              label="Tipi di Rischio"
              v-model="form.request_input.tipi_rischio"
              @input="onInputRiskTypes"
              :options="getRisks()"
            />
            <base-select
              vid="compagnie"
              name="compagnie"
              label="Compagnia"
              v-model="form.request_input.compagnie"
              :taggable="true"
              :multiple="true"
              :closeOnSelect="false"
              :disabled="insurersDisabled"
              :options="getInsurers()"
              @select="onChangeInsurer()"
              @remove="onChangeInsurer()"
            />
            <b-button
              :pressed.sync="myToggleI"
              variant="light"
              :disabled="insurersDisabled"
              class="mb-2"
              @click="myToggleI ? selectAllInsurers() : deselectAllInsurers()"
              >{{
                myToggleI ? "Deseleziona tutti" : "Seleziona tutti"
              }}</b-button
            >
            <base-select
              vid="ramo"
              name="ramo"
              label="Ramo"
              :taggable="true"
              v-model="form.request_input.rami"
              :options="risk_branches"
              :multiple="true"
              :disabled="insurersDisabled"
              @select="onSelectBranch()"
              @remove="onRemoveBranch()"
              :closeOnSelect="false"
            />
            <b-button
              :pressed.sync="myToggleB"
              :disabled="insurersDisabled"
              variant="light"
              class="mb-2"
              @click="myToggleB ? selectAllBranches() : deselectAllBranches()"
              >{{
                myToggleB ? "Deseleziona tutti" : "Seleziona tutti"
              }}</b-button
            >
            <base-select
              vid="prodotto"
              name="prodotto"
              label="Prodotto"
              v-model="form.request_input.prodotti"
              :options="products"
              :disabled="!canSelectProducts || insurersDisabled"
              :multiple="true"
              :taggable="true"
              :closeOnSelect="false"
            />
            <b-button
              :pressed.sync="myToggleP"
              :disabled="!canSelectProducts || insurersDisabled"
              variant="light"
              class="mb-2"
              @click="myToggleP ? selectAllProducts() : deselectAllProducts()"
              >{{
                myToggleP ? "Deseleziona tutti" : "Seleziona tutti"
              }}</b-button
            >
            <base-radio
              name="raggruppa_produttori"
              vid="raggruppa_produttori"
              label="Raggruppare i produttori"
              v-model="form.request_input.raggruppa_produttori"
              :options="[
                { value: 'Y', text: 'Si' },
                { value: 'N', text: 'No' },
              ]"
              :rules="{ required: true }"
            />
            <base-radio
              name="stampa_polizze"
              vid="stampa_polizze"
              label="Stampare anche il dettaglio polizze"
              v-model="form.request_input.stampa_polizze"
              :options="[
                { value: 'Y', text: 'Si' },
                { value: 'N', text: 'No' },
              ]"
              :rules="{ required: true }"
            />
            <base-radio
              name="tutte_polizze"
              vid="tutte_polizze"
              label="Considerare anche polizze stornate/annullate/disdettate nel periodo considerato"
              v-model="form.request_input.tutte_polizze"
              :options="[
                { value: 'Y', text: 'Si' },
                { value: 'N', text: 'No' },
              ]"
              :rules="{ required: true }"
            />
          </b-card-text>
          <b-button
            type="submit"
            :disabled="invalid"
            variant="outline-lisaweb"
            size="sm"
            class="float-right"
          >
            Salva
          </b-button>
        </b-card>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import BaseSelect from "@/components/form/BaseSelect";
import BaseInput from "@/components/form/BaseInput";
import BaseMonthPicker from "@/components/form/BaseMonthPicker";
import { mapGetters } from "vuex";
import common from "@/components/reports/common.vue";
import moment from "moment";
import BaseRadio from "@/components/form/BaseRadio";
moment.locale("it");

export default {
  extends: common,
  data() {
    return {
      myToggleS: false,
      myToggleI: false,
      myToggleB: false,
      myToggleP: false,
      salesmen_taggable: true,
      isMonthOnly: true,
      insurersDisabled: false,
      insurers: [],
      risk_branches: [],
      products: [],
      canSelectBranch: false,
      canSelectProducts: false,
      form: {
        format: null,
        report_id: this.reportId,
        request_input: {
          anno: null,
          compagnie: [],
          rami: [],
          mese_a: null,
          mese_da: null,
          produttori: [],
          prodotti: [],
          raggruppa_produttori: [],
          stampa_polizze: null,
          tipi_rischio: [],
          tutte_polizze: null,
        },
      },
    };
  },
  components: {
    BaseRadio,
    BaseInput,
    BaseSelect,
    BaseMonthPicker,
  },
  methods: {
    onInputRiskTypes(val) {
      if (val) {
        this.insurersDisabled = true;
        this.canSelectProducts = true;
        this.form.request_input.compagnie = [];
      } else {
        this.insurersDisabled = false;
      }
    },
    onChangeInsurer() {
      this.risk_branches = this.getBranches()(
        this.form.request_input.compagnie
      );
      this.canSelectBranch = true;
      if (!this.form.request_input.compagnie.length == 1) {
        this.form.request_input.prodotti = [];
        this.products = [];
        this.canSelectProducts = false;
      }
    },
    onSelectBranch() {
      this.products = this.getProducts()(
        this.form.request_input.compagnie,
        this.form.request_input.rami,
        null
      );
      this.canSelectProducts = this.form.request_input.compagnie.length === 1 ? true : false;
    },
    onRemoveBranch() {
      if (this.form.request_input.rami.length == 0) {
        this.canSelectProducts = false;
      } else {
        this.products = this.getProducts()(
          this.form.request_input.compagnie[0],
          this.form.request_input.rami,
          1
        );
      }
    },
    changeTaggable(val) {
      this.salesmen_taggable = val;
    },
    selectAllSalesmen() {
      this.form.request_input.produttori = this.getSalesmen().map(
        (salesman) => salesman.value
      );
    },
    deselectAllSalesmen() {
      this.form.request_input.produttori = [];
    },
    selectAllInsurers() {
      this.form.request_input.compagnie = this.getInsurers().map(
        (insurer) => insurer.value
      );
      this.renderAllBranches();
    },
    deselectAllInsurers() {
      this.form.request_input.compagnie = [];
    },
    renderAllBranches() {
      if (this.form.request_input.compagnie.length === 0) return;
      this.risk_branches = this.getBranches()();
    },
    selectAllBranches() {
      this.form.request_input.rami = this.risk_branches.map((branch) => branch.value);
      this.onSelectBranch();
    },
    deselectAllBranches() {
      this.form.request_input.rami = [];
      this.form.request_input.prodotti = [];
    },
    selectAllProducts() {
      this.form.request_input.prodotti = this.products.map((branch) => branch.value);
    },
    deselectAllProducts() {
      this.form.request_input.prodotti = [];
    },
    createReportCustom() {
      this.form.request_input.mese_da = moment(
        this.form.request_input.mese_da
      ).format("MM");
      this.form.request_input.mese_a = moment(
        this.form.request_input.mese_a
      ).format("MM");
      if (typeof this.form.request_input.compagnie === "number")
        this.form.request_input.compagnie = [this.form.request_input.compagnie];
      this.$emit("saveReport", this.form);
    },
    ...mapGetters("auth", {
      getSalesmen: "salesmen",
      getInsurers: "insurers",
      getBranches: "branches",
      getProducts: "products",
      getRisks: "risks",
    }),
  },
};
</script>
